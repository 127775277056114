import { Utensils } from 'lucide-react'
import React from 'react'

export function Logo({
	size,
}: {
	size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}) {
	const logoSize =
		size === 'h6'
			? 'w-4 h-4'
			: size === 'h5'
				? 'w-6 h-6'
				: size === 'h4'
					? 'w-7 h-7'
					: size === 'h3'
						? 'w-8 h-8'
						: size === 'h2'
							? 'w-10 h-10'
							: 'w-14 h-14'
	const hSize =
		size === 'h6'
			? 'text-h6'
			: size === 'h5'
				? 'text-h5'
				: size === 'h4'
					? 'text-h4'
					: size === 'h3'
						? 'text-h3'
						: size === 'h2'
							? 'text-h2'
							: 'text-h1'

	return (
		<div className="flex w-fit flex-row place-items-center gap-4">
			<Utensils className={logoSize} />
			<h1 className={hSize}>JPG Eats</h1>
		</div>
	)
}
